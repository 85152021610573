<template>
  <div class="complaint content-index" >
    <div class="header">
      <div class="complaint-top">
         <el-page-header
          @back="$router.go(-1)"
          content="投诉建议"
        ></el-page-header></div>
      <el-button
        type="primary"
        icon="el-icon-plus"
        class="btn-add"
        size="small"
        @click="complaint"
        >我要投诉/建议</el-button
      >
    </div>
    <el-tabs v-model="stateValue" type="card" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane
        :label="item.label"
        :name="item.name"
        v-for="(item, index) in tabPane"
        :key="index"
      >
        <div slot="label">
          <el-badge
            :value="item.notViewed"
            class="item"
            :max="99"
            v-if="item.notViewed > 0"
          >
            <span>{{ item.label }}</span>
            <span v-show="item.quantity != ''">（{{ item.quantity }}）</span>
          </el-badge>
          <div v-else>
            <span>{{ item.label }}</span>
            <span v-show="item.quantity != ''">（{{ item.quantity }}）</span>
          </div>
        </div>
      </el-tab-pane>
      <Table
        :buttonType="true"
        :current-value="pageData.current"
        :orientation="'center'"
        :serial-number="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        :height="'60vh'"
        :buttonWidth="150"
        @operationClick="operationClick"
        @handleCurrentChange="handleCurrentChange"
      />
    </el-tabs>
    <complaintAddition
      :dialogVisible="dialogVisible"
      :addLoading="addLoading"
      @closeDialog="closeDialog"
      @complaintAdd="complaintAdd"
    ></complaintAddition>
    <Details
      :dialogVisible="dialogDetails"
      @closeDialog="closeDialog"
      :detailedData="detailedData"
      :dialogLoading="dialogLoading"
    ></Details>
  </div>
</template>

<script>
import Table from "@/components/table2";
import complaintAddition from "./component/complaintAddition";
import Details from "./component/details";
import {
  page,
  complaintAdd,
  statusCount,
  complaintDetail,
  deleteSuggestions,
} from "@/api/complaint";
export default {
  name: "complaint",
  components: {
    Table,
    complaintAddition,
    Details,
  },
  data() {
    return {
      total: 0,
      pageData: {
        current: 1,
        size: 10,
        handleStatus: "",
      },
      stateValue: "10",
      tableData: [],
      detailedData: {},
      titleListData: [
        {
          prop: "typeDesc",
          label: "反馈类别",
          width: "150",
        },
        {
          prop: "status",
          label: "反馈状态",
          width: "150",
          bool:true
        },
        {
          prop: "suggestionsNumber",
          label: "编号",
          width: "150",
        },
        {
          prop: "feedbackDeptName",
          label: "反馈部门",
        },
        {
          prop: "belongDeptName",
          label: "归属部门",
        },
        {
          prop: "feedbackQuestion",
          label: "反馈问题",
        },
        {
          prop: "createdTime",
          label: "提交时间",
        },
        {
          prop: "latestReply",
          label: "最新回复",
        },
      ],
      tabPane: [
        {
          label: "全部",
          name: "10",
          notViewed: "",
          quantity: "",
        },
        {
          label: "待处理",
          name: "0",
          notViewed: "",
          quantity: "",
        },
        {
          label: "处理中",
          name: "1",
          notViewed: "",
          quantity: "",
        },
        {
          label: "已处理",
          name: "2",
          notViewed: "",
          quantity: "",
        },
      ],
      title: "",
      dialogVisible: false,
      dialogDetails: false,
      loading: false,
      addLoading: false,
      dialogLoading: false,
    };
  },
  created() {
    this.page();
  },
  methods: {
    // tabs切换
    handleClick(val) {
      if (val.name == 10) {
        this.pageData.handleStatus = "";
      } else {
        this.pageData.handleStatus = val.name;
      }
      this.pageData.current = 1;
      this.page();
    },
    //分页查询
    page() {
      this.loading = true;
      page(this.pageData).then((res) => {
        console.log(res, "数据");
        if (res.data.code == 0) {
          this.total = res.data.data.total;
          this.tableData = res.data.data.records?.map((item) => {
            if (item.handleStatusDesc == "待处理") {
              item.buttonList = [
                {
                  name: "查看详情",
                  size: "medium",
                  type: "text",
                  code: "details",
                  width: "180",
                },
                {
                  name: "删除",
                  size: "medium",
                  type: "text",
                  color: "red",
                  code: "del",
                  width: "180",
                },
              ];
            } else {
              item.buttonList = [
                {
                  name: "查看详情",
                  size: "medium",
                  type: "text",
                  code: "details",
                  width: "180",
                },
              ];
            }
            item.status = item.handleStatusDesc;
            return item;
          });
          this.statusCount();
        } else {
          this.$message.error(res.data.msg);
        }
        this.loading = false;
      });
    },
    // 分页
    handleCurrentChange(value) {
      console.log(value, "页码");
      this.pageData.current = value;
      this.page();
    },
    //添加
    complaintAdd(data) {
      this.addLoading = true;
      complaintAdd(data).then((res) => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.dialogVisible = false;
          this.page();
        } else {
          this.$message.error(res.data.msg);
        }
        this.addLoading = false;
      });
    },
    statusCount() {
      statusCount().then((res) => {
        let data = res.data.data;
        this.tabPane = this.tabPane?.map((item, index) => {
          if (index == 0 ) {
            item.quantity = data.allCount;
            item.notViewed = data.noViewCount;
          } else if (index == 1 ) {
            item.quantity = data.pendingCount;
            item.notViewed = data.pendingNoViewCount;
          } else if (index == 2 ) {
            item.quantity = data.processingCount;
            item.notViewed = data.processingNoViewCount;
          } else if (index == 3 ) {
            item.quantity = data.processedCount;
            item.notViewed = data.processedNoViewCount;
          }
          return item;
        });
      });
    },
    complaint() {
      this.title = "请填写";
      this.dialogVisible = true;
    },
    operationClick({ row, code }) {
      console.log(row);
      if (code == "details") {
        this.title = "详情";
        this.dialogDetails = true;
        this.dialogLoading = true;
        complaintDetail(row.suggestionsId)
          .then((res) => {
            if (res.data.code == 0) {
              this.detailedData = res.data.data;
              this.dialogLoading = false;
            } else {
              this.dialogLoading = false;
            }
          })
          .catch(() => {
            this.dialogLoading = false;
          });
        if (row.viewStatus == 0) {
          this.page();
        }
      }else if (code == "del") {
        this.$confirm("你确定要删除该条反馈信息吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deleteSuggestions(row.suggestionsId).then(({ data }) => {
              if (data.code == 0) {
                
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.page();
                // }
              } else {
                this.$message({
                  type: "error",
                  message: "删除失败!",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    closeDialog(val) {
      if (val == 1) {
        this.dialogVisible = false;
      } else {
        this.dialogDetails = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.complaint {
  overflow: auto;
  .header {
    padding: 17.5px 20px;
    overflow: hidden;

    > .complaint-top {
      float: left;
      color: #333;
      text-align: left;
      font-size: 20px;
      font-family: PingFang-SC-Bold;
    }

    > .btn-add {
      background: #b30d0d;
      border-color: #b30d0d;
      float: right;
    }
  }
}
.icon-box {
  position: absolute;
  width: 3px;
  height: 22px;
  left: 24px;
  background: #3894ff;
}
.el-tabs {
  margin: 0px 20px 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #e4e7ed;
}
::v-deep .el-badge__content.is-fixed {
  top: 10px;
  right: 10px;
}
</style>
