import request from '@/utils/request'
//投诉建议
export function postComplaint(data) {
    return request({
        url: '/sys/complaintAdvice',
        method: 'POST',
        data
    })
}
//分页查询
export function page(params) {
    return request({
        url: '/sys/suggestions/page',
        methods: 'get',
        params
    })
}
//建议投诉添加
export function complaintAdd(data) {
    return request({
        url: '/sys/suggestions/add',
        method: 'post',
        data: data
    })
}
//状态记录
export function statusCount() {
    return request({
        url: '/sys/suggestions/status/count',
        method: 'get',
    })
}
//查询详情
export function complaintDetail(id) {
    return request({
        url: '/sys/suggestions/detail/' + id,
        method: 'get'
    })
}
//获取部门下拉框
export function queryOneLevelDeptList() {
    return request({
        url: '/sys/dept/queryOneLevelDeptList',
        method: 'get'
    })
}
//获取具体部门的下级部门列表
export function queryLowerLevelDeptList(id) {
    return request({
        url: '/sys/dept/queryLowerLevelDeptList/' + id,
        method: 'get'
    })
}
//获取部门问题列表
export function getQuestion(id) {
    return request({
        url: '/sys/dept/question/getQuestion/' + id,
        method: 'get'
    })
}
//获取门店列表
export function listStore() {
    return request({
        url: '/sys/store/listStore',
        method: 'get'
    })
}
// 删除投诉建议
export function deleteSuggestions(suggestionsId) {
    return request({
        url: 'sys/suggestions/delete/'+suggestionsId,
        method: 'delete'
    })
}