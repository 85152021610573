<template>
  <div class="complaint">
    <div class="complain-header">
      <el-dialog
        :show-close="true"
        :visible="dialogVisible"
        top="10vh"
        width="55%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div slot="title" class="header-title">
          <span class="icon-box"></span>
          <span style="color:#000;font-size:16px">请填写</span>
        </div>
        <div class="reminder">
          <div style="padding-right: 10px">
            <span class="exclamatoryMark">
              <i class="el-icon-warning"></i>
            </span>
          </div>
          <div class="reminder-item">
            <span style="color: #ff8800">温馨提示：</span>
            <span style="font-size: 12px; color: #000">
              感谢您的反馈！为了能更好解决您的问题，请您认真填写内容和上传相关照片并核对联系方式，必要时我们会与您电话联系。
            </span>
          </div>
        </div>
        <div class="complaint-container">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="20%"
            class="demo-ruleForm"
            style="width: 60%; margin: 0 auto"
          >
            <el-form-item label="反馈类别" prop="type">
              <el-select v-model="ruleForm.type" placeholder="请选择反馈类别">
                <el-option
                  v-for="item in feedbackData"
                  :key="item.value"
                  :label="item.lable"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择部门" prop="feedbackDeptId">
              <el-select
                v-model="ruleForm.feedbackDeptId"
                placeholder="请选择部门"
                @change="departmentClick"
              >
                <el-option
                  v-for="(item, index) in department"
                  :key="index"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="ruleForm.feedbackDeptId != 2"
              label="归属部门"
              prop="belongDeptId"
            >
              <el-select
                v-model="ruleForm.belongDeptId"
                placeholder="请选择归属部门"
                @change="getQuestion"
              >
                <el-option
                  v-for="(item, index) in attributionList"
                  :key="index"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="ruleForm.feedbackDeptId == 2"
              label="门店列表"
              prop="belongDeptId"
            >
              <el-select
                v-model="ruleForm.belongDeptId"
                placeholder="请选择门店"
                @change="getQuestion"
              >
                <el-option
                  v-for="(item, index) in storeList"
                  :key="index"
                  :label="item.storeName"
                  :value="item.storeId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="问题类型" prop="questionTypeId">
              <el-select
                v-model="ruleForm.questionTypeId"
                placeholder="请选择问题类型"
              >
                <el-option
                  v-for="(item, index) in problemList"
                  :key="index"
                  :label="item.questionName"
                  :value="item.questionId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="反馈内容" prop="feedbackContent">
              <el-input
                style="width: 286px"
                type="textarea"
                placeholder="请输入反馈内容"
                v-model="ruleForm.feedbackContent"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
            <el-form-item v-if="ruleForm.type == 1" prop="image">
              <template slot="label">
                <span style="font-size: 14px; color: black">上传照片</span>
              </template>
              <el-upload
                action=""
                class="upload-demo"
                :file-list="fileList"
                :before-upload="beforeUpload"
                :http-request="requestData"
                :limit="10"
                list-type="picture"
                multiple
                :on-exceed="exceedUpload"
                :on-remove="handleRemove"
              >
                <el-button
                  size="small"
                  style="color: #3370FF;border: 1px solid #3370FF ;"
                  >点击上传</el-button
                >
              </el-upload>
              <el-button
                size="small"
                class="button"
                style="color: #353BFE ;border: 1px solid #353BFE ;"
                @click="photoFGraph"
                >我要拍照</el-button
              >
            </el-form-item>
            <el-form-item v-show="ruleForm.type != 1">
              <template slot="label">
                <span style="font-size: 14px; color: black">上传照片</span>
              </template>
              <el-upload
                action=""
                class="upload-demo"
                :file-list="fileList"
                :before-upload="beforeUpload"
                :http-request="requestData"
                :limit="10"
                list-type="picture"
                multiple
                :on-exceed="exceedUpload"
                :on-remove="handleRemove"
              >
                <el-button
                  size="small"
                  style="color: #3370FF ;border: 1px solid #3370FF ;"
                  >点击上传</el-button
                >
              </el-upload>
              <el-button
                size="small"
                class="button"
                style="color: #3370FF ;border: 1px solid #3370FF ;"
                @click="photoFGraph"
                >我要拍照</el-button
              >
            </el-form-item>
            <el-form-item label="怎么称呼" prop="feedbackUserName">
              <el-input
                v-model="ruleForm.feedbackUserName"
                placeholder="请输入名称"
              />
            </el-form-item>
            <el-form-item label="联系方式" prop="feedbackPhone">
              <el-input
                v-model="ruleForm.feedbackPhone"
                placeholder="请输入联系方式"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                :loading="addLoading"
                size="small"
                @click="submitForm('ruleForm')"
                >提交反馈</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <el-dialog center :visible.sync="photographVisible" width="50%" top="5vh">
      <div style="height: 500px">
        <div
          v-show="!hasAuth"
          class="camera_auth"
          style="width: 100%; height: 100%"
        >
          <el-button
            type="text"
            @click="getCompetence"
            style="
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            "
            ><img
              src="http://www.xiyitong.net/web/wash/dist/static/img/photo.117f2f5.png"
              alt=""
              style="
                width: 60px;
                height: 60px;
                margin-bottom: 12px;
                cursor: pointer;
              "
            />
            <p>打开摄像头</p></el-button
          >
        </div>
        <div
          v-show="hasAuth"
          class="camera_outer"
          style="width: 100%; height: 100%; position: relative"
        >
          <video
            id="videoCamera"
            autoplay
            style="width: 100%; height: 100%"
          ></video>
          <canvas
            id="canvasCamera"
            ref="videoCamera"
            :width="videoWidth"
            :height="videoHeight"
            style="
              position: absolute;
              top: 0;
              left: 0;
              visibility: hidden;
              width: 100%;
              height: 100%;
            "
          ></canvas>
          <el-button :loading="imageLoading" class="shoot" @click="setImage()">
            拍照
          </el-button>
          <el-button @click="stopNavigator">关闭摄像头</el-button>
        </div>
      </div>
      <div class="swiper-out" @mouseover="mouseover" @mouseleave="mouseleave">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) in imgSrc" :key="index">
            <div class="swiper-item">
              <div style="width: 100px; position: relative">
                <el-image
                  :src="item.url"
                  style="width: 100px"
                  fit="fill"
                  :preview-src-list="imgarr"
                ></el-image>
                <el-button
                  type="danger"
                  size="mini"
                  style="position: absolute; top: 0px; right: 0px"
                  icon="el-icon-delete"
                  circle
                  @click="toDelete(item.url)"
                ></el-button>
                <div>图片{{ index + 1 }}</div>
              </div>
            </div>
          </swiper-slide>
          <div
            class="left-icon"
            v-show="imgSrc.length > 5 && iconShow"
            slot="button-prev"
          >
            <i class="el-icon-d-arrow-left"></i>
          </div>
          <div
            class="right-icon"
            v-show="imgSrc.length > 5 && iconShow"
            slot="button-next"
          >
            <i class="el-icon-d-arrow-right"></i>
          </div>
        </swiper>
      </div>
      <el-button
        size="medium"
        class="enter-button"
        type="primary"
        @click="enterPhotograph"
      >
        确 认
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import { getAliYunSts } from "@/api/file/ali";
import "swiper/dist/css/swiper.css";
import OSS from "ali-oss";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { client } from "@/utils/alioss";
import { mapState } from "vuex";
import {
  queryOneLevelDeptList,
  queryLowerLevelDeptList,
  getQuestion,
  listStore,
} from "@/api/complaint";

export default {
  name: "",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    addLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let validateFileUrl = (rule, value, callback) => {
      if (this.newCredentialList.length != 0 || this.imgSrc.length != 0) {
        callback();
      } else {
        callback(new Error("请上传图片"));
      }
    };
    return {
      loading: false,
      determineLoading: false,
      fileList: [],
      aliSts: {},
      imgList: [],
      ruleForm: {
        initiator: "1", //发起方
        type: "", //类型
        feedbackDeptId: "", //反馈部门id
        belongDeptId: "", //归属部门id
        questionTypeId: "", //问题类型ID
        feedbackContent: "", //反馈内容
        feedbackImageList: [], //反馈图片集合
        feedbackUserName: "", //发起人
        feedbackPhone: "", //反馈联系电话
        feedbackUserId: "", //发起人id
      },
      rules: {
        type: [
          { required: true, message: "反馈类型不能为空", trigger: "change" },
        ],
        feedbackDeptId: [
          { required: true, message: "选择部门不能为空", trigger: "change" },
        ],
        belongDeptId: [
          { required: true, message: "归属部门不能为空", trigger: "change" },
        ],
        questionTypeId: [
          { required: true, message: "问题类型不能为空", trigger: "change" },
        ],
        feedbackContent: [
          { required: true, message: "反馈内容不能为空", trigger: "change" },
          {
            pattern: /^[^\s]+[\s]*.*$/,
            trigger: "blur",
            message: "不能只输入空格",
          },
        ],
        feedbackUserName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            pattern: /^[^\s]+[\s]*.*$/,
            trigger: "blur",
            message: "不能只输入空格",
          },
        ],
        feedbackPhone: [
          {
            pattern: /(^1\d{10}$)|(^[0-9]\d{7}$)/,
            trigger: "blur",
            message: "请输入正确的手机号码",
          },
          {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            trigger: "blur",
            message: "请输入数字",
          },
          {
            required: true,
            trigger: "blur",
            message: "手机号不能为空",
          },
        ],
        image: [
          { required: true, validator: validateFileUrl, trigger: "change" },
        ],
      },
      photographVisible: false,
      swiperOption: {
        loop: true, // 循环模式选项
        autoplay: false, //自动循环
        slidesPerView: 5, //设置slider容器能够同时显示的slides数量(carousel模式)。
        spaceBetween: 5, //在slide之间设置距离（单位px）。
        centeredSlides: false, //设置活动块居中
        navigation: {
          nextEl: ".right-icon",
          prevEl: ".left-icon",
        },
      },
      hasAuth: false,
      videoWidth: 0,
      videoHeight: 0,
      imageLoading: false,
      imgSrc: [],
      newCredentialList: [],
      thisCanvas: null,
      thisContext: null,
      thisVideo: null,
      iconShow: false,
      imgarr: [],
      arrLength: 0,
      //添加数据列表
      feedbackData: [
        {
          value: "1",
          lable: "我要投诉",
        },
        {
          value: "2",
          lable: "我要建议",
        },
      ],
      department: [], //部门
      attributionList: [], //归属列表
      problemList: [], //问题列表
      storeList: [],
    };
  },
  watch: {
    photographVisible(value) {
      if (value == false) {
        this.stopNavigator();
      }
    },
    dialogVisible(val) {
      if (val == false) {
        this.complaintUserInfo();
      }
    },
  },
  created() {
    this.queryOneLevelDeptList();
    this.userData();
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  methods: {
    complaintUserInfo() {
      this.ruleForm = {
        initiator: "1",
        type: "",
        feedbackDeptId: "",
        belongDeptId: "",
        questionTypeId: "",
        feedbackContent: "",
        feedbackImageList: [],
        feedbackUserName: this.userInfo.realName,
        feedbackPhone: this.userInfo.phone,
        feedbackUserId: this.userInfo.userId,
      };
      this.newCredentialList = [];
      this.imgarr = [];
      this.fileList = [];
      this.$refs.ruleForm.resetFields();
    },
    userData() {
      this.ruleForm.feedbackUserName = this.userInfo.realName;
      this.ruleForm.feedbackPhone = this.userInfo.phone;
      this.ruleForm.feedbackUserId = this.userInfo.userId;
    },
    //部门列表
    queryOneLevelDeptList() {
      queryOneLevelDeptList().then((res) => {
        this.department = res.data.data;
      });
    },
    //查询部门下的
    departmentClick(val) {
      this.ruleForm.belongDeptId = "";
      this.ruleForm.questionTypeId = "";
      if (val == 2) {
        this.listStore();
        return;
      }
      queryLowerLevelDeptList(val).then((res) => {
        this.attributionList = res.data.data;
      });
    },
    //归属下的问题列表
    getQuestion(val) {
      this.ruleForm.questionTypeId = "";
      let id = val;
      if (this.ruleForm.feedbackDeptId == 2) {
        id = this.ruleForm.feedbackDeptId;
      }
      console.log(id, "是啥");
      getQuestion(id).then((res) => {
        this.problemList = res.data.data;
        console.log(res, "归属下的问题列表");
      });
    },
    //获取门店列表
    listStore() {
      listStore().then((res) => {
        this.storeList = res.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.determineLoading = true;
          this.ruleForm.feedbackImageList = [
            ...this.newCredentialList,
            ...this.imgarr,
          ];
          this.$emit("complaintAdd", this.ruleForm);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.$emit("closeDialog", 1);
      this.complaintUserInfo();
    },
    handleRemove(file, fileList) {
      let arr = fileList?.filter((item) => {
        if (!item.bool) {
          return item.url;
        }
      });
      this.newCredentialList = arr.map((item) => item.url);
      this.imgSrc = fileList.filter((item) => {
        if (item.bool) {
          return item;
        }
      });
      this.imgarr = this.imgSrc.map((item) => item.url);
      this.fileList = fileList;
      this.arrLength = this.newCredentialList?.length + this.imgarr?.length;
    },
    beforeUpload() {
      if (this.fileList.length >= 10) {
        this.$message.error("最多只能上传和拍摄10张照片！");
        return false;
      }
    },
    exceedUpload() {
      this.$message.error("最多只能上传和拍摄10张照片！");
    },
    async requestData({ file }) {
      const imgName = "cashier_system/suggest/" + this.userInfo.storeId;
      const fileName = `${imgName}/${file.uid}`; //定义唯一的文件名
      try {
        let result = await client().put(fileName, file);
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.newCredentialList.push(result.url);
        this.fileList.push({ name: file.name, url: result.url });
        this.arrLength = this.newCredentialList.length + this.imgarr.length;
      } catch (err) {
        console.log(`阿里云OSS上传图片失败回调`, err);
      }
    },
    photoFGraph() {
      if (this.fileList.length >= 10) {
        this.$message.error("最多只能上传和拍摄10张照片！");
      } else {
        this.photographVisible = true;
        this.getCompetence();
      }
    },
    // 获取阿里云oss sts
    getAliYunSts() {
      getAliYunSts().then((response) => {
        this.aliSts = response.data.data;
      });
    },
    // 调用权限（打开摄像头功能）
    getCompetence() {
      // 获取阿里云配置
      this.getAliYunSts();
      const _this = this;
      this.$nextTick(() => {
        this.thisCanvas = document.getElementById("canvasCamera");
        this.thisContext = this.thisCanvas.getContext("2d");
        this.thisVideo = document.getElementById("videoCamera");
        // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices = {};
        }
        // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
        // 使用getUserMedia，因为它会覆盖现有的属性。
        // 这里，如果缺少getUserMedia属性，就添加它。
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function(constraints) {
            // 首先获取现存的getUserMedia(如果存在)
            var getUserMedia =
              navigator.webkitGetUserMedia ||
              navigator.mozGetUserMedia ||
              navigator.getUserMedia;
            // 有些浏览器不支持，会返回错误信息
            // 保持接口一致
            if (!getUserMedia) {
              return Promise.reject(
                new Error("getUserMedia is not implemented in this browser")
              );
            }
            // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
            return new Promise(function(resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          };
        }
        _this.hasAuth = true;
        setTimeout(() => {
          _this.videoWidth = _this.$refs.videoCamera.clientWidth;
          _this.videoHeight = _this.$refs.videoCamera.clientHeight;
          var constraints = {
            audio: false,
            video: {
              width: _this.videoWidth,
              height: _this.videoHeight,
              transform: "scaleX(-1)",
            },
          };
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function(stream) {
              // 旧的浏览器可能没有srcObject
              if ("srcObject" in _this.thisVideo) {
                _this.thisVideo.srcObject = stream;
              } else {
                // 避免在新的浏览器中使用它，因为它正在被弃用。
                _this.thisVideo.src = window.URL.createObjectURL(stream);
              }
              _this.thisVideo.onloadedmetadata = function(e) {
                _this.thisVideo.play();
              };
            })
            .catch((err) => {
              console.log(err);
            });
        }, 0);
      });
    },
    //  绘制图片（拍照功能）
    setImage() {
      this.imageLoading = true;
      let _this = this;
      // 点击，canvas画图
      _this.thisContext.drawImage(
        _this.thisVideo,
        0,
        0,
        _this.videoWidth,
        _this.videoHeight
      );
      // 获取图片base64链接

      let image = this.thisCanvas.toDataURL("image/png");
      // _this.imgSrc.push(image)
      if (_this.fileList.length < 10) {
        this.toUpload(image);
      } else {
        this.$message.error("最多只能上传和拍摄10张照片！");
        this.imageLoading = false;
      }
    },
    enterPhotograph() {
      this.photographVisible = false;
    },
    // 关闭摄像头
    stopNavigator() {
      if (this.thisVideo != null) {
        this.thisVideo.srcObject.getTracks()[0].stop();
        // this.thisVideo.srcObject.stop();
        this.hasAuth = false;
      }
    },
    // 上传图片
    toUpload(urlData) {
      let _this = this;
      let client = new OSS({
        region: _this.aliSts.region,
        accessKeyId: _this.aliSts.accessKeyId,
        accessKeySecret: _this.aliSts.accessKeySecret,
        stsToken: _this.aliSts.stsToken,
        bucket: _this.aliSts.bucket,
      });
      // 压缩图片，渲染页面
      _this.compressPic(urlData, "1", function(newBlob, newBase) {
        console.log(newBlob);
        const reader = new FileReader();
        reader.readAsArrayBuffer(newBlob);
        reader.onload = (event) => {
          // arrayBuffer转Buffer
          const buffer = new OSS.Buffer(event.target.result);
          // 上传
          // 随机命名
          let random_name = "sale/" + new Date().getTime() + "." + "png";
          client
            .put(random_name, buffer)
            .then((result) => {
              _this.imageLoading = false;
              // _this.$customAlert({message: '保存成功'})
              if (_this.fileList.length < 10) {
                _this.fileList.push({
                  url: _this.aliSts.domain + "/" + random_name,
                  name: random_name,
                  bool: true,
                });
                _this.imgSrc = [];
                _this.imgarr = [];
                _this.imgSrc = _this.fileList.filter((item) => {
                  if (item.bool) {
                    _this.imgarr.push(item.url);
                    return item;
                  }
                });
                _this.arrLength =
                  _this.newCredentialList?.length + _this.imgarr?.length;
              }
            })
            .catch((err) => {
              console.log("错误", err);
            });
        };
      });
    },
    // 参数: base64地址,压缩比例，回调函数(返回压缩后图片的blob和base64)
    compressPic: function(base64, scale, callback) {
      const that = this;
      let _img = new Image();
      _img.src = base64;
      _img.onload = function() {
        let _canvas = document.createElement("canvas");
        let w = this.width / scale;
        let h = this.height / scale;
        _canvas.setAttribute("width", w);
        _canvas.setAttribute("height", h);
        _canvas.getContext("2d").drawImage(this, 0, 0, w, h);
        let base64 = _canvas.toDataURL("image/jpeg");
        // 当canvas对象的原型中没有toBlob方法的时候，手动添加该方法
        if (!HTMLCanvasElement.prototype.toBlob) {
          Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
            value: function(callback, type, quality) {
              let binStr = atob(this.toDataURL(type, quality).split(",")[1]),
                len = binStr.length,
                arr = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
              }
              callback(new Blob([arr], { type: type || "image/png" }));
            },
          });
        } else {
          _canvas.toBlob(function(blob) {
            if (blob.size > 1024 * 1024) {
              that.compressPic(base64, scale, callback);
            } else {
              callback(blob, base64);
            }
          }, "image/jpeg");
        }
      };
    },
    // 删除图片
    toDelete(fileName) {
      this.$confirm("确认删除图片, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let _this = this;
          let client = new OSS({
            region: this.aliSts.region,
            accessKeyId: this.aliSts.accessKeyId,
            accessKeySecret: this.aliSts.accessKeySecret,
            stsToken: this.aliSts.stsToken,
            bucket: this.aliSts.bucket,
          });
          console.log(client, fileName);
          let fileName1 = fileName.substring(
            fileName.indexOf(this.aliSts.domain) + this.aliSts.domain.length + 1
          );
          client
            .delete(fileName1)
            .then((result) => {
              this.$message.success("删除成功");
              _this.fileList = _this.fileList.filter(
                (item) => item.url != fileName
              );
              _this.imgSrc = _this.imgSrc.filter(
                (item) => item.url != fileName
              );
              _this.imgSrc = [];
              _this.imgarr = [];
              _this.imgSrc = _this.fileList.filter((item) => {
                if (item.bool) {
                  _this.imgarr.push(item.url);
                  return item;
                }
              });
              _this.arrLength =
                _this.newCredentialList?.length + _this.imgarr?.length;
            })
            .catch((err) => {
              console.log("错误", err);
            });
        })
        .catch(() => {
          // this.$customAlert({message: '取消删除'})
        });
    },
    mouseover() {
      this.iconShow = true;
    },
    mouseleave() {
      this.iconShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-list {
  display: flex;
  flex-wrap: wrap;
}
::v-deep .el-upload-list--picture .el-upload-list__item {
  margin: 0;
  padding: 0;
  height: 80px;
  width: 80px;
  margin: 10px 10px 0 0;
}
::v-deep .el-upload-list__item-name {
  display: none;
}
::v-deep .el-upload-list--picture .el-upload-list__item-thumbnail {
  margin: 0;
  height: 90px;
  width: 90px;
}
::v-deep .el-upload-list__item-status-label {
  z-index: 99;
}
::v-deep .el-upload-list__item .el-icon-close {
  color: red;
  z-index: 99;
  font-weight: 700;
}
.complaint-container {
  padding: 20px;
  background-color: #fff;
}
.icon-box {
  display: inline-block;
  background: #3370FF;
  width: 4px;
  height: 20px;
  border-radius: 2px;
  vertical-align: bottom;
  margin-right: 5px;
}
.reminder {
  display: flex;
  flex-direction: row;
  background: #fff3e5;

  .exclamatoryMark {
    display: flex;
    text-align: center;
    padding: 20px 0 0 15px;
    border-radius: 50%;
    i {
      font-size: 25px;
      color: rgb(255, 136, 0);
      position: relative;
      top: -8px;
    }
  }
  .reminder-item {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }
}
::v-deep .el-input__inner {
  width: 220px;
}
.complain-header {
  ::v-deep .el-dialog__header {
    margin: 0;
  }
}
::v-deep .el-dialog__header {
  margin: 20px;
}
::v-deep .el-dialog__body {
  padding: 0px 20px;
}
.button {
  position: absolute;
  top: 5px;
  left: 100px;
}
.camera_outer {
  display: flex;
  height: 400px;
  overflow: auto;
}
::v-deep .el-input__inner {
  width: 286px;
}
.shoot {
  width: 60px;
  height: 60px;
  font-size: 15px;
  background: rgba(43, 172, 45, 0.6);
  color: #fff;
  border: 0 none;
  outline: none;
  border-radius: 50%;
  margin: 0 0.2rem;
  position: absolute;
  bottom: 15px;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 10px;
  }

  & + button {
    position: absolute;
    bottom: 15px;
    left: 5px;
  }
}
.swiper-slide {
  width: 20% !important;
  margin: 0 !important;
}
.swiper-out {
  padding: 20px 50px;
  height: 120px;
}
.left-icon {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-80%);
  z-index: 999;
  cursor: pointer;
  i {
    color: #fff;
    font-size: 30px;
  }
}
.right-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-80%);
  z-index: 999;
  cursor: pointer;
  i {
    color: #fff;
    font-size: 30px;
  }
}
.swiper-slide {
  text-align: center;
  height: 76%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  position: relative;
  // transform: scale(0.6);
}

.swiper-slide-next {
  // transform: scale(0.8);
  z-index: 998 !important;
}
::v-deep .el-icon-warning {
  color: #e6a23c;
}

.swiper-slide-active {
  z-index: 999 !important;
}
.enter-button {
  position: relative;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
