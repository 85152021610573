<template>
  <el-dialog
    :show-close="true"
    :visible="dialogVisible"
    top="10vh"
    width="55%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div slot="title" class="header-title">
      <span class="icon-box"></span>
      <span style="color: #000;font-size:16px">详情</span>
    </div>
    <div v-loading="dialogLoading">
      <el-row>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="8" style="display: flex">
            <span class="description">反馈类别：</span>
            <el-input v-model="detailedData.typeDesc" disabled />
          </el-col>
          <el-col :span="8" style="display: flex">
            <span class="description">状态：</span>
            <el-input v-model="detailedData.handleStatusDesc" disabled />
          </el-col>
          <el-col :span="8" style="display: flex">
            <span class="description">提交时间：</span>
            <el-input v-model="detailedData.createdTime" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="8" style="display: flex">
            <span class="description">反馈部门：</span>
            <el-input v-model="detailedData.feedbackDeptName" disabled />
          </el-col>
          <el-col :span="8" style="display: flex">
            <span class="description">归属部门：</span>
            <el-input v-model="detailedData.belongDeptName" disabled />
          </el-col>
          <el-col :span="8" style="display: flex">
            <span class="description">反馈问题：</span>
            <el-input v-model="detailedData.questionTypeDesc" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-row style="margin-bottom: 18px; display: flex">
              <el-col class="description" style="width: 73px">反馈人：</el-col>
              <el-col :span="18">
                <el-input
                  v-model="detailedData.feedbackUserName"
                  class="text1"
                  disabled
                  style="width: 100%"
                />
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 18px; display: flex">
              <el-col class="description" style="width: 75px">
                联系电话：
              </el-col>
              <el-col :span="18">
                <el-input
                  v-model="detailedData.feedbackPhone"
                  class="text1"
                  disabled
                  style="width: 100%"
                />
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 18px; display: flex">
              <span class="description" style="width: 73px">图片：</span>
              <el-image
                v-for="(item, index) in detailedData.feedbackImageList"
                v-show="index < 3"
                :key="index"
                :preview-src-list="detailedData.feedbackImageList"
                :src="item"
                style="width: 40px; height: 40px; padding-right: 5px"
              />
              <span
                v-if="
                  detailedData.feedbackImageList
                    ? detailedData.feedbackImageList.length > 3
                    : false
                "
                class="imgs"
              >
                +{{ detailedData.feedbackImageList.length }}
              </span>
            </el-row>
          </el-col>
          <el-col :span="16" style="display: flex">
            <span class="description" style="width: 85px">反馈内容：</span>
            <el-input
              v-model="detailedData.feedbackContent"
              class="feedback-content"
              disabled
              :rows="7"
              resize="none"
              type="textarea"
            />
          </el-col>
        </el-row>
      </el-row>
      <el-row style="padding-bottom: 10px">
        <span class="icon-box"></span>
        <span style="color: #000; font-size: 16px">跟进记录</span>
      </el-row>
      <div class="trackProgress">
        <div class="noRecord" v-if="noRecord">暂无跟进记录 ~</div>
        <el-row
          style="padding: 5px 40px"
          v-for="(item, index) in detailedData.operateList"
          :key="index"
        >
          <el-col>
            <i
              class="el-icon-success icon"
              :style="{
                color:
                  item.operateDesc == '待处理'
                    ? '#B30D0D'
                    : item.operateDesc == '处理中'
                    ? '#FE8800'
                    : '#1D8E33',
              }"
            ></i>
            <span style="padding-right: 10px">{{ item.operateDesc }}</span>
            <span>{{ item.operateTime }}</span>
          </el-col>
          <el-col style="padding: 10px 22px 0">
            <span style="padding-right：20px">回复：</span>
            <span>{{ item.content }}</span>
            <el-divider v-if="index < 1" />
          </el-col>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    detailedData: {
      type: Object,
      default: () => {},
    },
    dialogLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noRecord: false,
    };
  },
  watch: {
    detailedData(val) {
      if (val.operateList.length > 0) {
        this.noRecord = false;
      } else {
        this.noRecord = true;
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped lang="scss">
.icon-box {
  display: inline-block;
  background: #3370FF;
  width: 4px;
  height: 18px;
  border-radius: 2px;
  vertical-align: bottom;
  margin-right: 5px;
}
.details-form {
  padding: 20px;
  ::v-deep .el-divider--horizontal {
    margin: 0;
  }
}
.description {
  text-align: right;
  width: 105px;
  line-height: 32px;
}
.text1 {
  display: inline-block;
  vertical-align: bottom;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-dialog__body {
  padding: 5px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
::v-deep .el-form--inline .el-form-item__label {
  padding-left: 10px;
}
.imgs {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #e8e8e8;
  cursor: pointer;
}
.icon {
  margin-left: -17px;
  padding: 0 10px;
  font-size: 20px;
}
.noRecord {
  height: 100px;
  text-align: center;
}
</style>
